import React from "react";
import Layout from '../components/layout'
import Hero from "../components/hero";
import HeroImage from "../images/bywater.png";
import './nola.scss'

export default () => {
  return (
    <Layout hero={<Hero img={HeroImage} className="desktop-only" />}>
      <Hero className="mobile-only" img={HeroImage}/>
      <div className="nola-page flex flex-col items-center text-blue-dark text-xl max-w-lg m-auto px-5 xl:px-3">
        <div className='mb-5'>
          <h1 className='mb-2 mt-3 lg:mt-0'>welcome to new orleans!</h1>
          <p>There is no city in the world like New Orleans, and we are so excited to have our closest friends and family celebrate with us in this city. Get ready to indulge in everything that makes life worth living --great food, drinks, music and merriment!</p>
        </div>
        <div className='mb-5 lg:mb-10'>
          <h2 className="mb-2">getting here</h2>
          <p>Most airlines serve Louis Armstrong International Airport (MSY), 15 miles east of downtown New Orleans. A taxi from the airport to the French Quarter is a flat rate of $36 for up to 2 people. For more than 2 passengers, it's $15 per person. Uber and Lyft charge comparable rates. Cross the street at the pedestrian crosswalk located outside of baggage claim 6 and follow signs to the Transportation Center lot. We highly recommend using taxi/Uber/Lyft (and even streetcars!) over renting a car.</p>
        </div>
        <div className='h-100 w-full mb-5'>
          <svg version="1.1" id="Layer_1" x="0px" y="0px"
             viewBox="150 325 490 310">
            <g id="Layer_1_1_">
                <polygon className="macrohood-vector lakeview" points="186,199.5 182.5,384 193,384 199,388.5 211,384 216.5,375.5 213.5,363.5 246.5,349.5 275.5,371.5
                  281.5,366.5 299,378 304.5,368 293,356 299,352 304.5,336 310,303.5 310,220 315,204.5 312,183 300.5,183 285,173.5 278,179
                  270,179 268.5,184.5 261.5,180.5 256.5,184 248.8,182.5 242.5,188 227,186 214,191.5 213.5,211.5 207,211.5 207,196.5 	"/>
                <polygon className="macrohood-vector nola-east" points="317.5,183 317.5,209 315,215.5 315,241 312.5,252.5 315,265.5 312.5,277 315,303.5 310,325.5
                  323.5,325.5 316.8,346.5 321,346.5 316.8,354.5 348.5,354.5 383,379.5 397,406.5 436.5,397.5 515.5,414.5 518,421.5 515.5,435.5
                  521,445 563.5,468 568.5,465 572,470.5 608,371 630.5,354 630.5,86 550.5,129.5 548.5,124.5 547.5,114.5 540.5,130 524.5,119.5
                  512,107.5 509.5,96.5 505,88.5 498.5,82 492,82 491.5,73 486,76 489,109.5 486,123.5 488,148.5 479,154.5 465,159 446.5,144.5
                  372.5,158 344.5,162.5 331,164.5 	"/>
                <polygon className="macrohood-vector marigny-bywater" points="401,416.5 405.5,409.5 437,404.5 512,420 508.5,430 512,440.5 506,440.5 461,430.5 459.5,435.5
                  441,429.5 424,433 	"/>
                <polygon className="macrohood-vector french-quarter" points="416.5,437 391.5,419 368,456.5 395.5,476.5 395.5,463.5 406.5,440.5 	"/>
                <polygon className="macrohood-vector cbd" points="344.5,447.8 398.5,483 401,501 372.8,494.5 368,511.5 322.5,485.5 334,470.5 338,459.5 	"/>
                <polygon className="macrohood-vector mid-city" points="182.5,390 179,411 175.5,422.5 185,429 206,433.5 223,445 248.8,459 267.5,453.5 317.5,482.5
                  331.5,463.5 331.5,455.5 344.5,436.2 361.8,452 368,444 317.5,410.5 322.5,402 283,372 276.5,377.5 248.8,358 221,368.5 227.5,384
                  218,384 216.5,392 201.5,395 190.5,388.5 	"/>
                <polygon className="macrohood-vector treme" points="340.5,384 326,408.5 370.5,437.5 385.5,419 	"/>
                <polygon className="macrohood-vector esplinade" points="310,333.5 302,358 310,365.5 306,380.5 326,396 339.5,377.5 389,413 392.2,408 378,384 344.5,360.5
                  315,361.5 310,351.5 315,333.5 	"/>
                <polygon className="macrohood-vector uptown" points="175.5,429.5 148,485.5 157.5,539.5 161.8,612 167.5,621 187.5,629 228.5,634 260.5,634 364.5,594
                  392.2,571 405,542 401,506 376.5,500 372,519 266.5,460 248.8,466 204,440.5 	"/>
                <polygon className="macrohood-vector algiers" points="630.5,543 580,511.5 538,487 466,455 440,455 424,455 424,499 434,520 541,622 630.5,622 	"/>
            </g>
            <text transform="matrix(1 0 0 1 462.5212 190.1914)" className="map-text">New Orleans East</text>
            <text transform="matrix(1 0 0 1 537.4667 394.9678)"><tspan x="0" y="0" className="map-text">Lower </tspan><tspan x="2.7" y="16.8" className="map-text">Ninth </tspan><tspan x="3" y="33.6" className="map-text">Ward</tspan></text>
            <text transform="matrix(1 0 0 1 446.6001 354.9678)"><tspan x="0" y="0" className="map-text">Upper </tspan><tspan x="2" y="16.8" className="map-text">Ninth </tspan><tspan x="2.2" y="33.6" className="map-text">Ward</tspan></text>
            <text transform="matrix(1 0 0 1 367.1318 214.9678)" className="map-text">Gentilly</text>
            <text transform="matrix(1 0 0 1 490.5308 517.9678)" className="map-text">Algiers</text>
            <text transform="matrix(1 0 0 1 242.1978 571.9678)" className="map-text">Uptown</text>
            <text transform="matrix(1 0 0 1 310.1138 537.9678)"><tspan x="0" y="0" className="map-text">Garden </tspan><tspan x="0.5" y="16.8" className="map-text">District</tspan></text>
            <text transform="matrix(1 0 0 1 178.3447 505.9678)" className="map-text">Carrollton</text>
            <text transform="matrix(1 0 0 1 222.6333 258.9678)" className="map-text">Lakeview</text>
            <text transform="matrix(1 0 0 1 220.4136 414.9678)" className="map-text">Mid-City</text>
            <text transform="matrix(1 0 0 1 349.3364 484.9678)" className="map-text">CBD</text>
            <text transform="matrix(1 0 0 1 302.916 366.9678)"><tspan x="0" y="0" className="map-text">Esplanade</tspan><tspan x="13.9" y="16.8" className="map-text">Ridge</tspan></text>
            <text transform="matrix(1 0 0 1 405.293 418.9678)" className="map-text">Marigny</text>
            <text transform="matrix(1 0 0 1 458.3999 427.9678)" className="map-text">Bywater</text>
            <text transform="matrix(1 0 0 1 332.8115 413.4678)" className="map-text">Treme</text>
            <text transform="matrix(1 0 0 1 380.3896 443.9678)"><tspan x="0" y="0" className="map-text">French </tspan><tspan x="-1.8" y="16.8" className="map-text">Quarter</tspan></text>
          </svg>
        </div>
        <div className="h-100 w-full mb-5 where-to-stay">
          <div className='mb-5'>
            <div className='my-3'>
              <h2 className="my-2">where to stay</h2>
              <p>As a courtesy we have reserved a small number of hotel rooms in and near the French Quarter, however, there are many other options in the area that may be more suitable for your needs. The wedding will take place in the French Quarter, but the Friday night reception as well as post-wedding late night festivities will take place in the Marigny/Bywater neighborhoods should you want to find something in that area.</p>
            </div>
            <div className="mb-3">
              <p><a href='link-unstyled' target="_blank" href="https://www.voodoofestival.com/experience/">Voodoo Festival</a> also takes place this weekend so it would be best to book your accommodations as soon as possible. This is also something to consider if you’d like to stay through Sunday and buy a 1-day ticket to the festival!</p>
            </div>
            <div className="mb-3">
              <p>Again, this is a busy weekend, so hotels will book up and prices will only increase. Please let us know if we can be of any help!</p>
            </div>
            <div className='mb-3'>
              <a className='link-unstyled' target="_blank" href="https://www.hi-chateau.com/"><h3 className="mb-1">Holiday Inn Chateau LeMoyne (1/4 mile to ceremony)</h3></a>
              <p>Standard King: $229/night + 15.2% tax</p>
              <p className="mb-2">Standard Double (2 full beds): $249/night + tax </p>
              <p>Reservation Procedures:</p>
              <p>Call: 1-833-263-3569</p>
              <p>Refer to Group Code: ZZP</p>
              <p>Group Name: Cantos and LaNasa Wedding Block</p>
              <p>Dates: 10/25-27/2019</p>
              <p>Book by September 25, 2019</p>
            </div>
            <div className='mb-3'>
              <a className='link-unstyled' target="_blank" href="http://bookings.ihotelier.com/bookings.jsp?groupID=2492914&hotelID=96256"><h3 className="mb-1">The Whitney Hotel (1/2 mile to ceremony)</h3></a>
              <p className="mb-2">$189/night + tax</p>
              <p>Reservation Procedures:</p>
              <p>Call: 1-504-581-4222 or visit <a className="link-unstyle" target="_blank" href="http://bookings.ihotelier.com/bookings.jsp?groupID=2492914&hotelID=96256">this link</a></p>
              <p>Group Name: LaNasa Wedding Block</p>
              <p>Dates: 10/25-27/2019</p>
              <p>Book by September 25, 2019</p>
            </div>
            <div className='mb-3'>
              <a className='link-unstyled' target="_blank" href="https://www.holidayinn.com/redirect?path=hd&brandCode=HI&localeCode=en&regionCode=1&hotelCode=MSYDT&_PMID=99801505&GPC=CLW&viewfullsite=true"><h3 className="mb-1">Holiday Inn Downtown Superdome (1 mile to ceremony)</h3></a>
              <p className="mb-2">$229/night + tax</p>
              <p>Reservation Procedures:</p>
              <p>Call: 1-800-535-7830 or visit <a className="link-unstyle" target="_blank" href="https://www.holidayinn.com/redirect?path=hd&brandCode=HI&localeCode=en&regionCode=1&hotelCode=MSYDT&_PMID=99801505&GPC=CLW&viewfullsite=true">this link</a></p>
              <p>Refer to Group Code: CLW</p>
              <p>Group Name: Cantos and LaNasa Wedding Block</p>
              <p>Dates: 10/25-27/2019</p>
              <p>Book by September 25, 2019</p>
            </div>
            <div className='mb-3'>
              <a className='link-unstyled mb-1' target="_blank" href="https://www.airbnb.com/s/New-Orleans--LA--United-States/homes?allow_override%5B%5D=&checkin=2019-10-25&checkout=2019-10-27&ne_lat=29.982827772021942&ne_lng=-90.03224574608242&query=New%20Orleans%2C%20LA%2C%20United%20States&refinement_paths%5B%5D=%2Fhomes&s_tag=dj1eU6bQ&search_by_map=true&sw_lat=29.916464752140374&sw_lng=-90.10008121680818&zoom=13s%20St%2C%20New%20Orleans%2C%20LA%2070130%2C%20USA/1/0/0/0/1/1/results/0/"><h3 className="mb-1">Airbnb</h3></a>
              <p>We recommend using Airbnb to find affordable housing for any group size. Please keep in mind that shotgun housing is typical in New Orleans, where rooms are arranged one behind the other with no hallway. We will be staying in an Airbnb home in the Bywater! </p>
            </div>
          </div>
        </div>
        <div className="w-full mb-5">
          <h2 className="mb-2">our favorite places</h2>
          <p className='mb-2'>Some of our favorite spots can be found in the google map below. Some of the highlights:</p>
          <ul className='places' style={{paddingInlineStart: 20}}>
            <li>Wine & dine in the open air while listening to live music at <strong>Bacchanal's</strong></li>
            <li><strong>Frenchmen Street</strong> for late night live music and drinks</li>
            <li>Take in a river view while strolling through <strong>Crescent Park</strong></li>
            <li>Enjoy beignets and cafe au lait 24/7 at <strong>Café du Monde</strong></li>
            <li>Check out the shopping and historic homes around <strong>Magazine Street</strong></li>
          </ul>
        </div>
        <div className="w-full mb-5">
          <div class="map-overlay" onClick={(e) => e.target.style.pointerEvents = 'none'}></div>
          <iframe className="gmap" src="https://www.google.com/maps/d/u/0/embed?mid=1VDXIEGn3jTGwLeyLoTfwa2p03WBBGQV3" style={{width: '100%', height: '100%'}}></iframe>
        </div>
        <div className="w-full mb-5">
          <h2 className="mb-2">halloween and other spooky events in new orleans</h2>
          <div className="mb-3">
            <a className="link-unstyled" target="_blank" href="www.themortuary.net">
              <h3 className="mb-2 text-blue-dark">The Mortuary</h3>
            </a>
            <p className="mb-1">
              The white columned Victorian mansion on Canal Street, built in 1872, once was a funeral home. General admission is $30, with VIP and “fast passes” available. Donate blood to The Blood Center, which will be on site at the venue, and receive a free express admission ticket. Hours vary.{" "}
              <a className="link-unstyled text-blue-dark" target="_blank" href="https://goo.gl/maps/KS6rQ9NFBrf2tRic7">
                4800 Canal Street.
              </a>
            </p>
          </div>
          <div className="mb-3">
            <a className="link-unstyled" target="_blank" href="www.826neworleans.org">
              <h3 className="mb-2 text-blue-dark">A Dark and Stormy Night</h3>
            </a>
            <p className="mb-1">Thursday, October 24</p>
            <p className="mb-1">6pm-9:30pm</p>
            <p className="mb-1">
              The Halloween party features a costume contest, a ghost story cocktail contest and a dance party - all in the name of youth literacy. Proceeds benefit 826 New Orleans, a nonprofit writing center in the 7th ward that publishes the work of young authors ages 6 to 18. Boucherie and other restaurants will provide small plates to pair with cocktails, and DJ Soul Sister will perform a dramatic reading of students’ writings. Tickets start at $75.{" "}
              <a className="link-unstyled text-blue-dark" target="_blank" href="https://g.page/hotelpeterandpaul?share">
                Hotel Peter and Paul.
              </a>
            </p>
          </div>
          <div className="mb-4">
            <a className="link-unstyled" target="_blank" href="http://www.broussards.com/">
              <h3 className="mb-2 text-blue-dark">Halloween Drag Brunch</h3>
            </a>
            <p className="mb-1">Friday, October 25</p>
            <p className="mb-1">11am and 1:30pm</p>
            <p className="mb-1">
              Drag and Halloween both involve dressing up, so a Halloween drag brunch feels natural. Moanalot Fonataine, Gia GiaVanni and Coca Mesa will perform to Halloween classics at Broussard’s Restaurant & Courtyard as guests sip specialty cocktails. Costumes are encourage. Brunch starts at $49.{" "}
              <a className="link-unstyled text-blue-dark" target="_blank" href="https://goo.gl/maps/KvmUa3d2FoR7eLMG9">
                819 Conti Street.
              </a>
            </p>
          </div>
          <div className="mb-4">
            <a className="link-unstyled" target="_blank" href="http://www.neworleanscitypark.com/events/brews-and-boos">
              <h3 className="mb-2 text-blue-dark">Brews and Boos</h3>
            </a>
            <p className="mb-1">Friday, October 25</p>
            <p className="mb-1">10:30pm to 1:30am</p>
            <p className="mb-1">
              There are amusement rides and a costume contest featuring categories like “Best Group Costume” at this adults-only benefit for New Orleans City Park. Craft beer and batch cocktail samples will be available, and there’s a nacho station. Tickets start at $30.{" "}
              <a className="link-unstyled text-blue-dark" target="_blank" href="https://goo.gl/maps/mWd9KqvV6qFCVm9YA">
                Carousel Gardens Amusement Park and Storyland.
              </a>
            </p>
          </div>
          <div className="mb-4">
            <a className="link-unstyled" target="_blank" href="www.events.audubonnatureinstitute.org/boo">
              <h3 className="mb-2 text-blue-dark">Boo at the Zoo</h3>
            </a>
            <p className="mb-1">Friday, October 25- Sunday, October 27</p>
            <p className="mb-1">Friday and Saturday 5pm-9pm</p>
            <p className="mb-1">Sunday 4pm-8pm</p>
            <p className="mb-1">
              With a Ghost Train, Monster Maze, Mummy Manor and Trick-or-Treat Lane, the event at the Audubon Zoo for children up to 12 years old features games, live entertainment, inflatables and encounters with animals that are endangered. $20 per person.{" "}
              <a className="link-unstyled text-blue-dark" target="_blank" href="https://goo.gl/maps/zc846gUgdcfTMW8Z7">
                6500 Magazine Street.
              </a>
            </p>
          </div>
          <div className="mb-4">
            <a className="link-unstyled" target="_blank" href="www.scoutislandscreampark.com">
              <h3 className="mb-2 text-blue-dark">Scout Island Scream Park</h3>
            </a>
            <p className="mb-1">Friday, October 25 - Saturday, November 2</p>
            <p className="mb-1">
              The massive Halloween attraction at New Orleans City Park includes three fright zones, three haunted houses, a haunted tour, weekend hayrides, thrill rides, a dance floor and more. A scare-free kid zone features a haunted mansion bounce house, and there will be trick-or-treating on Halloween night. Admission and hours vary. Entrance at Marconi and Harrison avenues. {" "}
            </p>
          </div>
        </div>
        <div className="mb-4">
          <a className="link-unstyled" target="_blank" href="www.neworleansnightmare.com">
            <h3 className="mb-2 text-blue-dark">New Orleans Nightmare</h3>
          </a>
          <p className="mb-1">
            The haunted house has three main attractions, each centering around clowns, nightmares or zombies. There also are 5-minute escape rooms and a pitch-black maze. Drinks are available at Bar 13. Admission and hours vary.{" "}
            <a className="link-unstyled text-blue-dark" target="_blank" href="https://goo.gl/maps/fAwbn1Ubhpy165py8">
              319 Butterworth Street, Jefferson (20 min drive from French Quarter).
              </a>
          </p>
        </div>
      </div>
    </Layout>
  )
}
